<template>
  <v-container fill-height>
    <!-- <v-row class="pa-0 ma-0" align="stretch" justify="start" dense>
      <v-col class="ma-0 pa-0" cols="12"> -->
    <v-card
      elevation="4"
      class="rounded-lg mx-auto pa-0"

    >
      <!-- max-height="100" -->
      <!-- max-width="1200" -->
      <v-img
        :lazy-src="$ImageAssets('default', 'reseller_portal1024x1024')"
        :src="$ImageAssets('default', 'reseller_portal1024x1024')"
        contain
        height="85vh"
      />
    </v-card>

  </v-container>
</template>

<script>
export default {
  name: "Dashboard",
};
</script>
