import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    overlay: {
      color: "",
      textColor: "",
      percent: 0,
      show: false,
      textOverlay: "",
    },
    resellerData: {},
    userAgent: {
      _locales: [],
      geoname_id: null,
      iso_2: null,
      name: null,
    },
    nickname: null,
    domain: null,
    publicDomain: null,
    availableBalance: null,
    auth: false,
    persianInvoice: false,
    allowMoadianInvoice: false,
    hideSidebar: false,
    minSidebar: false,
    crm: {
      account: null,
      account_id: null,
      last_name: null,
      first_name: null,
      deactivate: false,
      block: false,
      ban: false,
      project_id: null,
      project_name: null,
      project_balance: null,
      project_deactivate: false,
      project_ban: false,
    },
    filterValue: {},
    snacks: [],
  },

  getters: {
    getResellerData(state) {
      return state.resellerData
    },
    getUserAgent(state) {
      return state.userAgent
    },
    getAuth(state) {
      return state.auth
    },
    getNickname(state) {
      return state.nickname
    },
    getDomain(state) {
      return state.domain
    },
    getHideSidebar(state) {
      return state.hideSidebar
    },
    getMinSidebar(state) {
      return state.minSidebar
    },
    getFilterValue(state) {
      return state.filterValue
    },
    snacks(state) {
      return state.snacks
    },
    getState: (state) =>
      (key, value = null) => {
        if (value) {
          return state[key][value];
        }
        return state[key];
      },
  },

  mutations: {
    showOverlay(state, boolean) {
      if (boolean === false) {
        state.overlay = Object.assign({}, { show: false });
      } else {
        state.overlay.show = true;
      }
    },
    setOverlay(state, data) {
      state.overlay = Object.assign({}, data);
    },
    setResellerData(state, data) {
      state.resellerData = Object.assign({}, data);
    },
    setUserAgent(state, data) {
      state.userAgent = Object.assign({}, data);
    },
    setNickname(state, nickname) {
      state.nickname = nickname
    },
    setDomain(state, domain) {
      state.domain = domain
    },
    setPublicDomain(state, public_domain) {
      state.publicDomain = public_domain
    },
    setAvailableBalance(state, balance) {
      state.availableBalance = balance
    },
    setAuth(state, auth) {
      state.auth = auth
    },
    setPersianInvoice(state, boolean) {
      state.persianInvoice = boolean;
    },
    setAllowMoadianInvoice(state, boolean) {
      state.allowMoadianInvoice = boolean;
    },
    setHideSidebar(state, hide) {
      state.hideSidebar = hide
    },
    setMinSidebar(state, min) {
      state.minSidebar = min
    },
    setCRM(state, data) {
      Object.keys(data).forEach(key => {
        state.crm[key] = data[key]
      })
    },

    // saving all of filter key value pair in state management
    setFilterValues(state, values) {
      state.filterValue = Object.assign({}, values)
    },

    // use adapter design pattern 
    setFilterValue(state, [key, value]) {
      if(Array.isArray(value)) state.filterValue[key] = [...value]
      else state.filterValue[key] = value
    },



    addSnack(state, newSnack) {
      if (newSnack.text) {
        // console.log("heyyyyy", newSnack)
        state.snacks.push(newSnack)
      }
    },
    removeSnack(state, index) {
      state.snacks.splice(index, 1)
      // state.snacks[0].showFlag = false
    },
  },

  actions: {
  },

})
