import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "./store";
import i18n from "./i18n";

import Dashboard from '../views/Dashboard.vue'

// handeling navigationduplicated Avoided redundant navigation to current location
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import("../views/Login.vue"),
    meta: {
      guest: true,
      title: i18n.t("LOGIN_PAGE_TITLE")
    },
  },
  {
    path: '/forgot_password',
    name: 'ForgotPassword',
    component: () => import("../views/ForgotPassword.vue"),
    meta: {
      guest: true,
      title: i18n.t("FORGOT_PASSWORD_PAGE_TITLE")
    },
  },
  {
    path: '/reset_password',
    name: 'ResetPassword',
    component: () => import("../views/ResetPassword.vue"),
    meta: {
      guest: true,
      title: i18n.t("RESET_PASSWORD_PAGE_TITLE")
    },
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      title: i18n.t("DASHBOARD_PAGE_TITLE")
    },
  },
  {
    path: '/client_settings',
    name: 'ClientSettings',
    component: () => import("../views/ClientSettings.vue"),
    meta: {
      requiresAuth: true,
      title: i18n.t("CLIENT_SETTINGS_PAGE_TITLE")
    },
  },
  {
    path: '/did_settings',
    name: 'DidSettings',
    component: () => import("../views/DidSettings.vue"),
    meta: {
      requiresAuth: true,
      title: i18n.t("DID_SETTINGS_PAGE_TITLE")
    },
  },
  {
    path: '/management',
    name: 'Management',
    component: () => import("../views/Management.vue"),
    meta: {
      requiresAuth: true,
      title: i18n.t("MANAGEMENT_PAGE_TITLE")
    },
  },
  {
    path: '/crm/:uu_id',
    name: 'CRM',
    component: () => import("../views/CRM.vue"),
    meta: {
      requiresAuth: true,
      title: i18n.t("CUSTOMER_RELATIONSHIP_MANAGEMENT_PAGE_TITLE")
    },
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: () => import("../views/Wallet.vue"),
    meta: {
      requiresAuth: true,
      title: i18n.t("WALLET_PAGE_TITLE")
    },
  },
  // {
  //   path: '/migrate',
  //   name: 'Migrate',
  //   component: () => import("../views/Migrate.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     title: i18n.t("MIGRATE_PAGE_TITLE")
  //   },
  // },
  {
    path: '/finance',
    name: 'Finance',
    component: () => import("../views/Finance.vue"),
    meta: {
      requiresAuth: true,
      title: i18n.t("FINANCE_PAGE_TITLE")
    },
  },
  // {
  //   path: '/500',
  //   name: 'ServerError',
  //   component: () => import('../views/ServerErrorPage.vue'),
  //   meta: {
  //     requiresAuth: false,
  //     title: i18n.t("SERVER_ERROR_PAGE_TITLE")
  //   },
  // },
  
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../views/NotFoundPage.vue'),
    meta: {
      requiresAuth: false,
      title: i18n.t("NOT_FOUND_PAGE_TITLE")
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.VUE_APP_FRONTEND,
  // base: '/',
  base: process.env.BASE_URL,
  routes
}) 

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if(!window.localStorage.getItem('token')) {
      next({
        path: '/login',
        // params: { nextUrl: to.fullPath }
        // query: { redirect: to.fullPath }
      })
      // console.log("hook 1.1")
    } else {
      next()
      // console.log("hook 1.2")
    }
  }
  else if(to.matched.some(record => record.meta.guest)) {
    if(!window.localStorage.getItem('token')) {
      next()
      // console.log("hook 2.1")
    } else {
      next({path: '/'})
      // console.log("hook 2.2")
    }
  }
  else {
    next();
    // console.log("hook 3.1")
  }



})

router.afterEach((to, from) => {
  Vue.nextTick( () => {
    document.title = to.meta.title ? to.meta.title : 'Telobal';
  });
})






// router.beforeEach((to, from, next) => {
  
//   // console.log('localStorage: ' + localStorage.getItem('userToken'))
//   // console.log(typeof(localStorage.getItem('userToken')))
//   if(to.matched.some(record => record.meta.requiresAuth)) {
//     // console.log('requiresAuth: ' + to.matched.some(record => record.meta.requiresAuth))
//     if(!localStorage.getItem('userToken')) {
//       next({
//         path: '/login',
//         params: { nextUrl: to.fullPath }
//         // query: { redirect: to.fullPath }
//       })
//       // console.log("hook 1.1")
//     }
//     else {
//       next()
//       // console.log("hook 1.2")
//     }
//   }
//   else if(to.matched.some(record => record.meta.guest)) {
//     // console.log('guest: ' + to.matched.some(record => record.meta.guest))
//     if(!localStorage.getItem('userToken')) {
//       next()
//       // console.log("hook 2.1")
//     }
//     else {
//       next({path: '/wellcome'})
//       // console.log("hook 2.2")
//     }
//   }
//   else {
//     next()
//   }
// })

export default router
