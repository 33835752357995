export default {
  E1000: {
    en: "failed get token from gateway",
    fa: "توکن دریافت نشد از دروازه"
  },
  E1002: {
    en: "invalid amount value or type",
    fa: "مقدار یا نوع مقدار نامعتبر است"
  },
  E1004: {
    en: "invalid decimal place for this currency",
    // fa: ""
  },
  // E: {
  //   en: "",
  //   fa: ""
  // }
}