var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tooltip',{attrs:{"bottom":_vm.tooltipBottom,"top":_vm.top,"left":_vm.tooltipLeft,"right":_vm.tooltipRight,"color":_vm.changeIcon?'#2CBE60':_vm.btnColor},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":_vm.textMode ? false : true,"text":_vm.textMode ? true : false,"color":_vm.changeIcon?'#2CBE60':_vm.btnColor,"small":_vm.btnSmall,"x-small":_vm.btnXSmall},on:{"click":_vm.copyText}},'v-btn',attrs,false),on),[(!_vm.textMode)?_c('v-icon',{attrs:{"x-small":_vm.xSmall,"small":_vm.small,"large":_vm.large},domProps:{"textContent":_vm._s(_vm.changeIcon?'mdi-check-circle-outline':'mdi-content-copy')}}):_c('span',{domProps:{"textContent":_vm._s(_vm.text)}})],1)]}}])},[(!_vm.textMode)?_c('small',{staticClass:"font-weight-bold",domProps:{"innerHTML":_vm._s(
      _vm.changeIcon ? _vm.$t('COPIED') : 
      (_vm.btnColor == 'white' || _vm.btnColor == '#ffffff') ? 
      `<span class='primary--text'>${_vm.$t('COPY_TO_CLIPBOARD')}</span>` :
      _vm.$t('COPY_TO_CLIPBOARD')
    )}}):_c('small',{class:`font-weight-bold 
    ${(_vm.btnColor == 'white' || _vm.btnColor == '#ffffff') ? 'primary--text' : '' }`,domProps:{"innerHTML":_vm._s(`${_vm.tooltipText}`)}})])
}
var staticRenderFns = []

export { render, staticRenderFns }