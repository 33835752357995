<template>
  <!--
  :right="$vuetify.rtl"
  :style="{ background: $vuetify.theme.themes.light.background }"
-->
  <!--
  v-if="!$store.getters.getHideSidebar"
  :mini-variant="$store.state.getMinSidebar"
-->
  <v-navigation-drawer
    :value="$store.getters.getAuth"
    v-if="!$store.getters.getHideSidebar"
    :mini-variant="$store.getters.getMinSidebar"
    :right="$vuetify.rtl"
    style="z-index: 1"
    app
    stateless
    floating
  >
    <v-list nav>
      <v-list-item-group>
        <!-- sidebar header -->
        <v-list-item-avatar v-if="$store.getters.getMinSidebar">
          <!-- <v-img :src="require('@/assets/telobal/logo_640x640.png')" /> -->
          <v-img
            :src="$ImageAssets('private_logo', 'logo_640x640')"
          />
          <!--  -->
        </v-list-item-avatar>
        <v-list-item class="px-0 mx-0" v-if="!$store.getters.getMinSidebar">
          <!-- :src="require('@/assets/telobal/logo_slogan_400x98.png')" -->
          <v-img
            :src="$ImageAssets('private_logo', 'logo_slogan_400x98')"
            max-height="40px"
            alt="logo"
            contain
            eager
          />
        </v-list-item>
        <!-- sidebar items -->
        <div v-for="(item, i) in sidbarItems" :key="i">
          <!-- <v-list-item dense link :to="item.url" :active-class="customActive">  -->
          <v-list-item dense link :to="item.url" active-class="custom-active">
            <v-list-item-icon>
              <v-icon :class="item.class">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "SideBar",

  data() {
    return {
      x: true,
      sidbarItems: [
        {
          url: "/",
          icon: "mdi-home",
          name: this.$t("DASHBOARD_HOME"),
          class: "",
        },
        {
          url: "/client_settings",
          icon: "mdi-cogs",
          name: this.$t("CLIENT_SETTINGS"),
          class: "",
        },
        {
          url: "/did_settings",
          icon: "mdi-phone-settings",
          name: this.$t("DID_SETTINGS"),
          class: "",
        },
        {
          url: "/management",
          icon: "mdi-table-cog",
          name: this.$t("MANAGEMENT"),
          class: "",
        },
        {
          url: "/finance",
          icon: "mdi-finance",
          name: this.$t("FINANCE"),
          class: "",
        },
        // {
        //   url: "/migrate",
        //   icon: "mdi-crosshairs-question",
        //   name: this.$t("MIGRATE"),
        //   class: "",
        // },
        
      ],
    };
  },

  // mounted() {
  //   console.log('primary color', this.$vuetify.theme.themes.light.primary)
  // },

  // computed: {
  //   customActive() {
  //     let primaryColor = this.$vuetify.theme.themes.light.primary
  //     // let classActive = {
  //     //   'box-shadow': '0 5px 10px -4px rgba(94, 86, 105, 0.42)',
  //     //   'color': 'white !important',
  //     //   'background-color': `${this.$vuetify.theme.themes.light.primary} !important`
  //     // }
  //     let classActive = `box-shadow: 0 5px 10px -4px rgba(94, 86, 105, 0.42); color: white !important; background-color: ${primaryColor} !important`
  //     console.log((classActive))
  //     return classActive
  //   }
  // }
};
</script>
<style lang="scss">
a {
  font-weight: bold;
  text-decoration: none !important;
}
.custom-active {
  box-shadow: 0 5px 10px -4px rgba(94, 86, 105, 0.42);
  color: white !important;
  background-color: #2e4a76 !important;
}
</style>
