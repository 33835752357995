<template>
  <v-container>
    <v-overlay
      :value="$store.state.overlay.show"
      opacity="0.1"
      :color="$store.state.overlay.color || 'darkBlue'"
    >
      <v-row class="fill-height" align="center" justify="center">
        <v-col cols="12" md="6">
          <v-progress-circular
            :indeterminate="$store.state.overlay.percent ? false : true"
            color="primary"
            size="100"
            :value="$store.state.overlay.percent || 0"
          >
            <v-img 
              :src="$ImageAssets('private_logo', 'logo_640x640')"
              width="62" 
            />
          </v-progress-circular>
        </v-col>
        <v-col class="text-subtitle-1 text-center" cols="12">
          <span
            :style="
              $store.state.overlay.textColor
                ? { color: $store.state.overlay.textColor }
                : { color: 'darkBlue' }
            "
          >
            {{ $store.state.overlay.textOverlay }}
          </span>
        </v-col>
      </v-row>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  name: "OverlayLoading",

  watch: { // this is for overlay false when change route
    "$route.name": {
      immediate: true,
      handler() {
        this.$store.commit('setOverlay', { percent: null, show: false})
      },
    },
  //   "$store.state.overlay.percent": {
  //     immediate: false,
  //     handler() {
  //       if (this.$store.state.overlay.percent > 0) {this.$store.commit('setOverlay', {show: true})}
  //       // this.$store.commit('setOverlay', { percent: null, show: false})
  //     },
  //   }
  },

};
</script>

<!-- this component variable baind to overlay object in main store -->
<!-- for show overlay, object in store sould has 'show: ture' or 'percent has value' -->
<!-- overlay object in store:  
        overlay: {
          color : "",         // background color of <v-overlay>
          textColor : "",     // color of text over spinner 
          percent : null,     // percent of upload or download spiner
          show : false,       // boolean for show overlay
          textOverlay : ""    // text over spinner
        },
-->
<!-- mutation for overlay state: 
        setOverlay(state, data) {
          state.overlay = Object.assign({}, data)
        },
-->


<!-- we can handel upload overlay with custom axios:
  !!!!ADD STORE IN CUSTOM AXIOS 
        Request.interceptors.request.use(
          (config) => {
            .
            .
            .
            config.onUploadProgress = function (progressEvent) {
                let uploadPercentage = parseInt(
                    Math.round((progressEvent.loaded / progressEvent.total) * 100)
                );
                if (uploadPercentage === 100) store.commit("setOverlay", { percent: 0 })
                else store.commit("setOverlay", { percent: uploadPercentage })
            }
            .
            .
            .
          }
        );
  !!!!ADD TIMEOUTE IN REQUEST IN FILE FOR UPLOADING
--> 
                                      
                                      <!-- OR -->
                                    
<!-- mutation for percent in overlay state: 
        setOverlayPercent(state, percent) {
          state.overlay.percent = percent
        }
-->
<!-- javascript get data when upload or download data, we equalize progressEvent.loaded percentage to overlay.percent:
        let options =  {
          timeout: 60000,
          "Content-Type": "text/plain",
          onUploadProgress: (progressEvent) => {
            let uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
            if(uploadPercentage >= 100) this.$store.commit("setOverlayPercent", 0)
            else this.$store.commit("setOverlayPercent", uploadPercentage)
          },
            onDownloadProgress: (progressEvent) => {
              const total = parseFloat(progressEvent.currentTarget.responseHeaders['Content-Length'])
              const current = progressEvent.currentTarget.response.length
              let downloadPercentage = Math.floor(current / total * 100)
              if(downloadPercentage >= 100) this.$store.commit("setOverlayPercent", 0)
              else this.$store.commit("setOverlayPercent", downloadPercentage)
          },
        }
-->
<!-- see example in test.arjavand in api component -->
