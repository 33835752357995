import Axios from "axios";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

const origin = process.env.VUE_APP_BACKEND_API
  ? process.env.VUE_APP_BACKEND_API
  : document.location.origin;

const apiPath = process.env.VUE_APP_BACKEND_API_PATH
  ? process.env.VUE_APP_BACKEND_API_PATH
  : "/api/v1/";
  
const baseUrl = origin + apiPath;

const Request = Axios.create({
  timeout: 10000,
  baseURL: baseUrl,
});

Request.urls = {
  auth: { // 1 Day
    reseller: "/", // GET
    login: "/login/", // POST
    logout: "/logout/", // GET
    forgot_password: "/forgot_password/", // POST
    reset_password: "/reset_password/", // GET, POST
    change_password: "/change_password/", // POST
  },
  configs: { // 4 Day
    languages: "/main/languages/", //GET
    dashboard: "/dashboard/", // GET, PATCH
    main: "/main/", // GET, PATCH
    terms: "/main/default_terms/",
    network: "/network/", // GET, PATCH
    network_email: "/network/email/", // POST
    billing: "/billing/", // GET, PATCH
    global_currency: "/billing/global_currency/", // GET
    currency: "/billing/currency/", // GET, POST
    notifire: "/notifire/", // GET, PATCH
    primary_currency: "/billing/change_primary/", // POST
    delete_currency: "/billing/pk/", // DELETE
    default_template: "/template/default_template/", //
    template: "/template/", // GET, PATCH
    client: "/client/", // GET, PATCH
    customercare: "/customercare/pk/", // GET, POST, PATCH, DELETE
    gateway: "/gateways/", // GET, POST, DELETE
    sale_policy: "/sale_policy/pk/" // GET, POST, PATCH, DELETE
  },
  manage: { // 5 Day
    accounts: "/accounts/", // GET
    account_resend: "/accounts/pk/activate/resend/", // POST
    account_force: "/accounts/pk/activate/force/", // POST
    account_note: "/accounts/pk/note/", // PATCH
    projects: "/projects/", // GET
    numbers: "/numbers/", // GET
    orders: "/orders/", // GET
    payments: "/payments/", // GET
    invoices: "/invoices/", // GET
    cdr: "/cdr/", // GET
    sms_in: "/sms_in/", // GET
    sms_out: "/sms_out/", // GET
    persons: "/persons/", // GET
    groups: "/groups/", // GET
    caller_id: "/caller_id/", // GET
    login_as: "/login_as/" // GET
  },
  crm: { // 18 Day
    account: "/account/pk/", // GET, PATCH
    email: "/account/pk/email/", // GET, POST, PATCH, DELETE
    email_verify: "/account/pk/email/verify/", // POST
    phone: "/account/pk/phone/", // GET, POST, PATCH, DELETE
    phone_verify: "/account/pk/phone/verify/", // POST
    company: "/account/pk/company/", // GET, PATCH
    geodata: "/account/pk/geodata/", // GET, PATCH
    social_network: "/account/pk/social_network/", // GET, PATCH
    newsletter: "/account/pk/newsletter/", // GET, PATCH
    sessions: "/account/pk/sessions/", // GET, DELETE
    project: "/project/", // GET
    details: "/project/pk/", // GET, PATCH
    billing_address: "/project/pk/billing_address/", // GET, PATCH
    numbers: "/project/pk/numbers/", // GET
    // add_number: "/project/number/pk/add/",
    // create_number: "/project/number/pk/create/",
    number_sync: "/project/number/pk/did_sync/", // GET
    number_patch: "/project/number/pk/did_patch/", // GET
    number: "/project/number/pk/", // PATCH
    number_cancel: "/project/number/pk/cancel/", // POST
    number_sms_trunk: "/project/number/pk/sms_trunk/", // POST
    number_renew: "/project/number/pk/renew/", // GET, POST
    number_restore: "/project/number/pk/restore/", // GET, POST
    number_change_cycle: "/project/number/pk/change_cycle/", // GET, POST
    number_billing_options: "/project/number/pk/billing_options/", // GET
    number_requirements: "/project/number/pk/requirements/", // GET







    cdr: "/project/pk/cdr/", // GET
    caller_id: "/project/pk/caller_id/", // GET, POST, PATCH, DELETE
    rate: "/project/pk/caller_id/rate/", // POST
    verify: "/project/pk/caller_id/verify/", // POST
    delivery: "/project/pk/delivery/", // GET,
    persons: "/project/pk/persons/", // GET, POST, PATCH, DELETE
    persons_location: "/project/pk/persons/location/", // POST
    internal_number: "/project/pk/persons/internal_number/", // GET
    subscriber: "/project/pk/persons/subscriber/",  // GET, POST, PATCH, DELETE
    reset_password: "/project/pk/persons/subscriber/reset_password/", // GET
    extra_phone: "/project/pk/persons/extra_phone/", // POST, DELETE
    phones: "/project/pk/persons/phones/", // PATCH
    groups: "/project/pk/groups/", // GET, POST, PATCH, DELETE
    sms_inbox: "/project/pk/sms/inbox/", // GET
    sms_outbox: "/project/pk/sms/outbox/", // GET
    finance_wallet: "/project/finance/pk/wallet/", // GET, PATCH
    payment: "/project/finance/pk/payment/", // GET, POST,
    // payment_json: "/project/finance/pk/payment_json/",
    persian_invoice: "/project/finance/pk/payment/persian_invoice/", // GET
    gateway_detail: "/project/finance/pk/payment/gateway_detail/", // GET
    wire: "/project/finance/pk/payment/wire/", // GET, PATCH, DELETE
    orders: "/project/finance/pk/orders/", // GET
    invoice: "/project/finance/pk/invoice/", // GET
    transaction: "/project/finance/pk/transaction/", // GET, POST,
    in_cart: "/project/finance/pk/in_cart/", // GET
  },
  did: { // 7 Day
    countries: "/countries/", // GET
    countries_coverages: "/countries/coverages", // GET
    pricing: "/pricing/", // GET, PATCH
    coverage: "/pk/", // GET, PATCH
    did_filter_update: "/did_filter_update/", // PATCH
    pstn_client: "/rate/pstn/client/", // GET
    pstn_reseller: "/rate/pstn/reseller/", // GET
    delivery_client: "/rate/sms_delivery/client/", // GET 
    delivery_reseller: "/rate/sms_delivery/reseller/", // GET
    sms_client: "/rate/sms_out/client/", // GET
    sms_reseller: "/rate/sms_out/reseller/", // GET
    terms: "/information/terms/", // GET, PATCH
    information: "/information/pk/", // GET, POST, PATCH, DELETE
    info_settings: "/information/settings/" // GET
  },
  migrations: { // !cleaning
    list: "/",
    create: "/account/create/",
    numbers: "/numbers/",
    add_number: "/numbers/create/",
    get_project: "/project/"
  },
  finance: { // 6 Day
    transactions: "/transactions/", // GET
    project: "/pk/project/", // GET
    persian_tax: "/persian_tax/", // GET, POST
    export: "/persian_tax/export/", // POST
    status: "/persian_tax/status/", // GET
    inquiries: "/persian_tax/inquiries_pending/", // GET
    inquiry: "/persian_tax/inquiry/", // GET
    cancel_moadian: "/persian_tax/cancel/", // GET
    details_moadian: "/persian_tax/invoice_detail/" // GET
  }
};

Request.formatUrl = function (url, pk) {
  // return url.replace(/pk/g, pk);
  return pk ? url.replace(/pk/g, pk) : url.replace('/pk/', '/')
};

Request.getUrl = function (name, path) {
  return name + this.urls[name][path];
};

Request.Overlay = true;

Request.interceptors.request.use(
  (config) => {
    if (Request.Overlay) {
      store.commit("showOverlay", true);
    }
    let token = window.localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    config.onUploadProgress = function (progressEvent) {
      let uploadPercentage = parseInt(
        Math.round((progressEvent.loaded / progressEvent.total) * 100)
      );

      if (uploadPercentage === 100) store.commit("setOverlay", { percent: 0 });
      else store.commit("setOverlay", { percent: uploadPercentage });
    };
    return config;
  },
  (error) => {
    store.commit("showOverlay", false);
    return Promise.reject(error);
  }
);

Request.interceptors.response.use(
  function (response) {
    // console.log("router from cu_axios.js: ", router);
    store.commit("showOverlay", false);
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status == 401) {
        // console.log("error.response.status=401 from cu_axios.js: ", error.response);
        window.localStorage.clear();
        // console.log("from pathname", window.location.pathname)
        // console.log("from search", window.location.search)
        let toPath = `${window.location.pathname}${Boolean(window.location.search)?window.location.search:''}`


        router.push({ path: '/login', query: { toPath: toPath } })
        // window.location.href = "/login";
      }
      if (error.response.status == 404) {
        // not found error
        if (process.env.NODE_ENV === "development") {
          console.log(
            "error.response.status=404 from cu_axios.js: ",
            error.response
          );
        } else {
          router.push({ name: 'NotFound' })
        }
      }
      if (error.response.status == 500) {
        // server error
        if (process.env.NODE_ENV === "development") {
          console.log(
            "error.response.status=500 from cu_axios.js: ",
            error.response
          );
        } else {
          // window.location.href = "/500";
          console.log(
            "error.response.status=500 from cu_axios.js: ",
            error.response
          );
        }
      }
    }
    store.commit("showOverlay", false);
    return Promise.reject(error);
  }
);

export default Request;
