// import './plugins/axios'
// import './registerServiceWorker'
import Vue from 'vue'
import App from './App.vue'
import router from './plugins/router';
import store from './plugins/store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';

// impoert global COMPONENT'
import Description from './core/components/Description.vue'
import CopyToClipboard from './core/components/CopyToClipboard.vue'
import CustomButton from './core/components/CustomButton.vue'
import Errors from './core/components/Errors.vue'
import ErrorsForm from './core/components/ErrorsForm'
import Header from './core/components/Header.vue'
import Pagination from './core/components/Pagination.vue'

// impoert global JS files
import Request from './plugins/cu_axios';
import Rules from './core/js/rules'
import prototypes from './core/js/prototypes'

// import STYLESHEET files
import "@/styles/style.css"
import "@/styles/flag.css"

// import global MIXINS
import { ImageURLMixin } from '@/core/mixins/global_mixin'

Vue.config.productionTip = false;

// use global COMPONENT
Vue.use(Description);
Vue.use(CopyToClipboard);
Vue.use(CustomButton);
Vue.use(Errors);
Vue.use(ErrorsForm);
Vue.use(Header);
Vue.use(Pagination);

// use global JS files
Vue.prototype.$http = Request;
Vue.prototype.$Rules = Rules;
Vue.use(prototypes);

// use global MIXINS
Vue.mixin(ImageURLMixin); 

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
