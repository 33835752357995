import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  rtl: false,
  theme: {
    themes: {
      light: {
        primary: '#2E4A76',// main color
        secondary: '#09F', 
        info: '#66B3E6',

        error: '#EF3E36',
        success: '#2CBE60',
        warning: '#F5BC00',

        gray: '#CCC',
        cream: '#FCFAEE',
        pink: '#FBF1EF',

        bgGray1: '#F7F6F3',
        bgGray2: '#E2E1E0',
        background: '#F7F6F3',
        
      },
      dark: {
        // primary: '#2E4A76',// main color
        // secondary: '#09F', 
        // info: '#66B3E6',

        // danger: '#EF3E36',
        // success: '#2CBE60',
        // warning: '#F5BC00',

        // gray: '#CCC',
        // cream: '#FCFAEE',
        // pink: '#FBF1EF',

        // bgGray1: '#F7F6F3',
        // bgGray2: '#E2E1E0',
        // background: '#F7F6F3'
      },
    },
  }
});