<template>
  <v-app id="app">
    <div class="identifire-class" v-if="['NotFound', 'Login'].includes($route.name) ? false : true"/>
<!-- v-if="appLoading" -->
    <v-main >
      <router-view />
    </v-main>

    <AppBar v-if="$store.getters.getAuth && ['NotFound', 'Login'].includes($route.name) ? false : true" />
    <SideBar v-if="$store.getters.getAuth && ['NotFound', 'Login'].includes($route.name) ? false : true" />
    <OverlayLoading />
    <SnackBar />
  </v-app>
</template>

<script>
import AppBar from "@/components/main/AppBar.vue"
import SideBar from "@/components/main/SideBar.vue"
import OverlayLoading from "@/core/components/OverlayLoading.vue"

export default {
  name: "App",

  components: {
    AppBar: () => import("@/components/main/AppBar.vue"),
    SideBar: () => import("@/components/main/SideBar.vue"),
    OverlayLoading: () => import("@/core/components/OverlayLoading.vue"),
    SnackBar: () => import("@/core/components/SnackBar.vue"),
  },

  data() {
    return {
      appLoading: false,

    };
  },
  
  created() {
    let valid = ["/login", "/reset_password", "/forgot_password"];
    let site = new URL(location.href);
    if (!valid.includes(site.pathname)) {
      if(localStorage.getItem('token')) {
        let url = this.$http.getUrl("auth", "reseller");
        this.$http.get(url)
        .then((response) => {
          this.$store.commit("setAuth", true);
          this.$store.commit("setPersianInvoice", response.data.persian_invoice)
          this.$store.commit("setAllowMoadianInvoice", response.data.allow_moadian_invoice)
          // console.log("response.data apppppppppppppppppppp", response.data);
          // console.log("terfcs", response.data.reseller)
          this.$store.commit("setResellerData", response.data.reseller);
          this.$store.commit("setUserAgent", response.data.user_agent);
          this.$store.commit("setNickname", response.data.nickname);
          this.$store.commit("setDomain", response.data.domain);
          this.$store.commit("setPublicDomain", response.data.public_domain);
          this.$store.commit("setAvailableBalance", response.data.available_balance);
          
          this.appLoading = true
        })
        .catch((error) => {
          if (process.env.NODE_ENV === "development") {
            console.log("domain not found", error);
          } else {
            // window.location.href = "/500";
            console.log("500 error");
          }
        })
      } else {
        window.location.href = "/login";
      }
    }
    else this.appLoading = true
  },

};
</script>
<style>
  .identifire-class {
    position: fixed !important;
    top: 0 !important;
    /* right: 0 !important; */
    height: 4px !important;
    width: 100vw !important;
    background-color: #2E4A76 !important;
    z-index: 800;
  }
</style>