<template>
  <v-app-bar
    :value="$store.getters.getAuth"
    color="#fff"
    class="mx-4 rounded"
    elevate-on-scroll
    app
  >
    <!-- <span class="darkBlue--text">
      <small> ({{ $t("YOUR_ACCESS_IS_ADMIN") }}) </small>
    </span> -->
    
    <!-- change sidebar situation -->
    <v-btn
      @click="sideBar"
      color="primary"
      small
      icon
    >
      <v-icon>
        {{ ($store.getters.getHideSidebar && $store.getters.getMinSidebar) ? "mdi-forwardburger" : "mdi-backburger" }}
      </v-icon>
    </v-btn>

    <!-- panel message -->
    <v-chip small class="mx-2" outlined color="primary"> 
      <v-icon  left color="success" v-text="'mdi-cash'" />
      <span 
        class="primary--text text-caption font-weight-bold" 
        v-html="$t(
          'BALANCE_APPBAR_{balance}_{name}', 
          {
            balance: $PriceSeprator($LowDecimal(+$store.getters.getState('availableBalance'))),
            name: $store.getters.getState('resellerData', 'co_name')
          }
        )" 
      />
    </v-chip>

    <v-spacer />

    <Search class="mx-1" />

    <!-- accounting buttons -->
    <v-menu bottom offset-y transition="slide-y-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-hover v-slot="{ hover }">
          <v-icon class="mx-1" v-bind="attrs" v-on="on" :color="hover ? '#0099FFDD' : 'primary'" v-text="'mdi-account-circle'" />
        </v-hover>
      </template>
      <v-sheet width="180px" class="px-3 py-2">
        <!-- account -->
        <span @click="showAccount = true" style="display: block !important; cursor: pointer !important;">
          <v-icon left color="success" small v-text="'mdi-account-circle-outline'"/>
          <span class="text-caption font-weight-bold success--text" v-text="$t('ACCOUNT_DETAIL')" />
        </span>

        <v-divider class="mt-2" />

        <!-- logout -->
        <span @click="show = true" style="display: block !important; cursor: pointer !important;" class="mt-1">
          <v-icon left color="error" small v-text="'mdi-location-exit'"/>
          <span class="text-caption font-weight-bold error--text" v-text="$t('LOGOUT')" />
        </span>
      </v-sheet>
    </v-menu>

    <!-- account modal -->
    <Account
      v-if="showAccount"
      :show="showAccount"
      @close="showAccount = false"
    />

    <!-- logout modal -->
    <Modal 
      v-if="show"
      :show="show"
      kind="logout"
      :message="{
        title: `${$t('LOGOUT')}`,
        text: `<span class='font-weight-black'>${$t('LOGOUT_MESSAGE')}</span>`
      }"
      :buttons="{
        no: { color: '#00000061', text: $t('LOGOUT_NO_BTN'), outlined: true },
        yes: { color: 'error', text: $t('LOGOUT_YES_BTN'), outlined: false },
      }"
      @noButton="show = false"
      @yesButton="exit"
    />
  </v-app-bar>
</template>

<script>
export default {
  name: "AppBar",

  components: {
    Account: () => import("@/components/main/Account.vue"),
    Modal: () => import("@/core/components/Modal.vue"), 
    Search: () => import("@/components/main/Search.vue"),
  },

  data() {
    return {
      show: false,
      showAccount: false,
    };
  },

  methods: {
    sideBar() {
      if(!this.$store.getters.getHideSidebar && !this.$store.getters.getMinSidebar) {
        this.$store.commit('setHideSidebar', false)
        this.$store.commit('setMinSidebar', true)
      }
      else if(!this.$store.getters.getHideSidebar && this.$store.getters.getMinSidebar) {
        this.$store.commit('setHideSidebar', true)
        this.$store.commit('setMinSidebar', true)
      }
      else if(this.$store.getters.getHideSidebar && this.$store.getters.getMinSidebar) {
        this.$store.commit('setHideSidebar', false)
        this.$store.commit('setMinSidebar', false)
      }
    },

    exit() {
      let url = this.$http.getUrl("auth", "logout");
      this.$http.get(url)
      .then((_) => {
        window.localStorage.clear();
        window.location.href = "/login";
        this.show = false
      })
    },
  },
};
</script>
