<template>
  <div class="inline-block-display">
    <!-- button tooltip -->
    <v-tooltip 
      v-if="Boolean(description)" 
      :color="color" 
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs" 
          v-on="on" 
          :disabled="disabled"
          @click.native.stop="$emit('click')"
          style="border-radius: 4px !important;"
          :icon="!Boolean(label)"
          elevation="0"
          tile
          outlined
          x-small
          :color="color"
        >
          <v-icon 
            v-if="Boolean(icon)" 
            :disabled="disabled"
            :left="Boolean(label)"
            :x-small="Boolean(label)"
            :small="!Boolean(label)"
            v-text="icon"
          />
          <small 
            :style="{ color: disabled ? '#00000061' : color }"
            class="font-weight-bold" 
            v-text="label"
          />
        </v-btn>
      </template>
      <small 
        class="font-weight-bold" 
        v-text="description"
      />
    </v-tooltip>
    <!-- button Without tooltip -->
    <v-btn
      v-else
      :disabled="disabled"
      @click.native.stop="$emit('click')"
      style="border-radius: 4px !important;"
      :icon="!Boolean(label)"
      elevation="0"
      tile
      outlined
      x-small
      :color="color"
    >
      <v-icon 
        v-if="Boolean(icon)" 
        :disabled="disabled"
        :left="Boolean(label)"
        :x-small="Boolean(label)"
        :small="!Boolean(label)"
        v-text="icon"
      />
      <small 
        :style="{ color: disabled ? '#00000061' : color }"
        class="font-weight-bold" 
        v-text="label"
      />
    </v-btn>
  </div>
</template> 

<script>
export default {
  install(Vue) {
    Vue.component("CustomButton", this);
  },

  props: {
    description: {
      type: String,
      default: null,
    },

    color: {
      type: String,
      default: '#000000DE',
    },

    icon: {
      type: String,
      default: null,
    },

    label: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    }
  },
};
</script>
<!--
  <CustomButton
    disabled
    description
    color
    icon
    label
    @click="function"
  />
-->