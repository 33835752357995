<template>
  <v-alert
    v-if="Boolean(text)"
    class="ma-0 text-start"
    border="left"
    color="#EF3E36"
    transition="scroll-y-transition"
    dense
    text
  >
    <small class="font-weight-medium" v-html="text" />
  </v-alert>
</template>

<script>
import finder from "@/data/error_codes";

export default {
  install(Vue) {
    Vue.component("ErrorsForm", this);
  },

  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      text: "",
    };
  },

  watch: {
    message: {
      immediate: true,
      handler() {
        if (this.message.hasOwnProperty("error_code")) {
          // this.$i18n.locale = 'fa'
          this.text = `
            ${finder(this.message.error_code, this.$i18n.locale)}&nbsp;&nbsp;
          <span class="font-weight-bold">(${this.message.error_code})</span>`;
        } else {
          this.text = Object.entries(this.message)
            .map((arr) => {
              return `<span class="font-weight-bold">${
                arr[0]
              }:</span>&nbsp;&nbsp;${arr[1].toString()}`;
            })
            .join("<br />");
        }
      },
    },
  },
};
</script>

<!-- USAGE
  <ErrorsForm v-if="Boolean(errors)" :message="errors"/>
  errors ==> error.response.data from .catch() Promiss
-->