export default {
  E1001: {
    en: "payment not found",
    fa: "پروژه اعتبار کم"
  },
  E1005: {
    en: "payment not found",
    fa: "پرداخت پیدا نشد"
  },
  E1006: {
    en: "payment not found",
    // fa: ""
  },
  // E: {
  //   en: "",
  //   fa: ""
  // }
}