<template>
  <v-row align="center" justify="start" class="my-1 mx-1 pa-0">
    <!-- title of input -->
    <v-col v-if="!condition" class="ma-0 pa-0" cols="auto">
      <span 
        class="font-weight-medium me-2 text-subtitle-2 text--secondary" 
        v-text="$t('ITEM_PER_PAGE')" 
      />
    </v-col>
    
    <!-- input for get itemPerPage -->
    <v-col v-if="!condition" class="ma-0 pa-0" cols="1">
      <v-select
        v-model="itemPerPage"
        :items="[10, 20, 30, 50, 100]"
        class="small-size my-0 py-0"
        item-text="text"
        item-value="value"
        hide-details
        dense
        outlined
        @change="paginat(1, +itemPerPage)"
      >
        <template v-slot:selection="{item}">
          <span
            class="font-weight-medium text-caption text--secondary"
            v-text="item"
          />
        </template>

        <template v-slot:item="{item}">
          <span
            class="font-weight-medium text-caption text--secondary"
            v-text="item"
          />
        </template>
      </v-select>
    </v-col>

    <!-- paginatin component for get page -->
    <v-col cols="5" class="ma-0 pa-0 text-start" v-if="pages > 1">
      <v-pagination
        v-model="currentPage"
        class="ma-0 pa-0 small-size-page"
        :length="pages"
        :total-visible="7"
        @next="paginat(+currentPage, +itemPerPage) "
        @previous="paginat(+currentPage, +itemPerPage)"
        @input="paginat(+currentPage, +itemPerPage)"
      />
    </v-col>

    <v-spacer />
    <!-- entering the intended page -->
    <v-col v-if="!condition && Boolean(pages > 7)" class="ma-0 pa-0 me-2" cols="auto">
      <v-menu
        v-model="getPage"
        :close-on-content-click="false"
        bottom
        left
        transition="scale-transition"
      >
        <!-- show field -->
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            v-bind="attrs"
            v-on="on"
            class="ma-0"
            color="primary"
            label
            small
          >
            <span class="font-weight-medium" v-text="$t('INTENDED_PAGE')" />
          </v-chip>
        </template>
        <!-- form of intended page -->
        <v-card max-width="300PX" class="pa-2" elevation="0">
          <v-form ref="intendedPageForm" v-model="intendedPageValid">
            <!-- field -->
            <div 
              style="width: 70px; display:inline-block"
              class="me-2"
            >
              <v-text-field
                v-model.number="intendedPage"
                @keydown.enter.prevent="intendedPageValid ? ((paginat(+intendedPage, +itemPerPage)), ($refs.intendedPageForm.reset()), (getPage = false)) : false"
                type="number"
                class="small-size my-0 py-0"
                :min="1"
                :max="pages"
                :rules="[$Rules.required, $Rules.maxNumber(intendedPage, pages), !notSelectCurrentPage(intendedPage)]"
                dense
                outlined
                hide-details
              />
            </div>
            <!-- button -->
            <v-hover v-slot="{ hover }">
              <v-btn
                :disabled="!intendedPageValid"
                @click="(paginat(+intendedPage, +itemPerPage)), ($refs.intendedPageForm.reset()), (getPage = false)"
                elevation="0"
                icon
                tile
                outlined
                x-small
                :color="hover ? 'primary' : '#00000099'"
                style="border-radius: 4px !important;"
              >
                <v-icon 
                  small
                  v-text="hover ? 'mdi-check-bold' : 'mdi-check'"
                />
              </v-btn>
            </v-hover>
          </v-form>
        </v-card>
      </v-menu>
    </v-col>

    <!-- chip for show total count -->
    <v-col v-if="!condition && Boolean(count)" class="ma-0 pa-0" cols="auto">
      <v-chip
        class="ma-0"
        color="primary"
        label
        outlined
        small
      >
        <span class="font-weight-medium" v-text="$t('TOTAL_ITEM_PAGINATION_MSG_{count}', {count: this.count})" />
      </v-chip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  install(Vue) {
    Vue.component("Pagination", this);
  },
  
  props: {
    page: Number,

    pages : Number,

    perPage: {
      type: Number,
      default: null,
    },   

    count: {
      type: Number,
      default: null,
    },

    // URLPaginated: {
    //   type: Boolean,
    //   default: false,
    // }
  },

  data() {
    return {
      itemPerPage: 10,
      currentPage: 1,
      getPage: false,
      intendedPageValid: true,
      intendedPage: null,
    };
  },

  computed: {
    condition() {
      return Boolean(this.pages == 1  && this.itemPerPage == 10)
    },
  },

  watch: {
    page: {
      immediate: true,
      handler() {
        this.currentPage = this.page;
      },
    },

    perPage: {
      immediate: true,
      handler() {
        this.itemPerPage = this.perPage;
      },
    },

    // getPage: {
    //   immediate: false,
    //   handler() {
    //     console.log("getPage", this.getPage)
    //     if(!this.getPage) {
    //       // this.$refs.intendedPageForm.reset()
    //       this.$refs.intendedPageForm.reset()
    //     }
    //   },
    // },

    // '$route.query.page': {
    //   immediate: true,
    //   handler() {
    //     console.log("$route.query.page", this.$route.query.page)
    //     // console.log("")
    //     if(this.URLPaginated && !this.$route.query.hasOwnProperty('page')) {
    //       // this.$router.replace({ query: {...this.$route.query, page: this.page} })
    //       this.paginat(+this.page, +this.itemPerPage)
    //     } else if(this.URLPaginated && this.$route.query.page != this.page) {
    //       this.paginat(
    //         +this.$route.query.page > this.pages ? this.pages : +this.$route.query.page, 
    //         +this.itemPerPage
    //       )
    //     }
    //   },
    // },
  },

  methods: {
    notSelectCurrentPage(page) {
      return +page == this.currentPage
    },

    paginat(current, per) {
      this.$emit('changePage', current, per)
      // if(this.URLPaginated) this.$router.replace({ query: {...this.$route.query, page: current} })
    }
  },
};
</script>

<style>
.deactivateClassDefault {
  color: #0000005E !important;
  cursor: default;
}
.updatedClassDefault {
  background-color: #2CBE6020;
  cursor: default;
}
.notAvailableClassDefault {
  text-decoration: line-through !important;
  text-decoration-color: #EF3E36DE !important;
  background-color: #F5BC0020;
  cursor: default;
}  

.classDefault {
  color: #000000DE !important;
  cursor: default;
}  
.selectedActiveClassDefault{
  background-color: #2E4A7620 !important;
  color: #000000DE !important;
  cursor: default;
}
.pointer {
  cursor: pointer !important;
}
/* v-icon.v-icon--dense */
.block-display {
  display: block !important;
}
.span-chip {
  display: block !important;
  max-height: 24px !important;
  min-height: 24px !important;
  min-width: 90px !important;
  max-width: 90px !important;
  border: 1px solid #9E9E9E !important;
  border-radius: 4px !important;
  padding: auto !important;
  /* text-justify: auto; */
}
.button-icon {
  border: 1px solid #2cbe60 !important;
  border-radius: 4px !important;
  padding: 2px !important;
}
.activeClass {
  color: #000000DE !important;
  cursor: pointer !important;
}
.cursorDefault {
  cursor: default !important;
}
.pointer{
  cursor: pointer !important;
}
.small-size .v-input__slot {
  max-height: 28px !important;
  min-height: 28px !important;
  font-size: 0.75rem !important
}
.small-size .v-input {
  font-size: 0.75rem !important;
}
.small-size .v-input__append-inner {
  margin-top: 2.8px !important;
}
.small-size  .v-input__prepend-inner {
  margin-top: 2.8px !important;
}
.small-size .v-icon.v-icon {
  font-size: 18px !important;
}
.small-size-page .v-pagination .v-pagination__item {
  /* max-width: 27px !important; */
  /* min-width: 27px !important; */
  max-height: 27px !important;
  min-height: 27px !important;
  font-size: 0.75rem !important;
  box-shadow: none !important;
  /* color: #0099FF !important; */
  font-weight: 500 !important;
  border: solid 0.5px #2E4A76 !important
}
.small-size-page .v-pagination .v-pagination__navigation {
  /* max-width: 27px !important;
  min-width: 27px !important; */
  max-height: 27px !important;
  min-height: 27px !important;
  font-size: 0.75rem !important;
  box-shadow: none !important;
  /* color: #0099FF !important; */
  border: solid 0.5px #2E4A76 !important;
  padding-top: 1px !important;
  padding-top: 1px !important;
}
.small-size-page .v-pagination .v-pagination__navigation .v-icon {
  color: #2E4A76 !important;
}
.small-size-page .v-pagination {
  width: auto !important;
}
.goldClass {
  background-color: #D4AF3720;
  cursor: pointer !important;
}
.silverClass {
  background-color: #B5B5BD20;
  cursor: pointer !important;
}
.bronzClass {
  background-color: #CD7F3220;
  cursor: pointer !important;
}

.small-list-item {
  min-height: 32px !important;
  max-height: 32px !important
}

/* .test .v-tooltip__content {
  padding: 5px 0px !important;
} */
</style>

<!--
  <Pagination :page="page" :item_per_page="item_per_page" @changePage="change" />

  this.$http.get(url, {
    params: {
      page: this.page,
      item_per_page: this.item_per_page,
    }
  })
-->