import errors400 from "./400"
import errors404 from "./404"

const error_codes = {
  E0000: {
    en: "contact support",
    fa: "با پشتیبانی تماس بگیرید"
  },
  ...errors400,
  ...errors404
}

function finder(code, locale) {
  if(Boolean(error_codes[code])) return error_codes[code][locale] || error_codes[code].en 
  else return error_codes.E0000[locale] || error_codes[code].en 
}

export default finder