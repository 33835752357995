import store from "@/plugins/store";
import router from '@/plugins/router';

export default {
  install(Vue) {
    // find key of item in localStorage
    Vue.prototype.$localStorageFinder = function (key) {
      try {
        var keyUTF8 = Vue.prototype.$utf8_to_b64(key);
        var valueUTF8 = Vue.prototype.$b64_to_utf8(
          localStorage.getItem(keyUTF8)
        );
        return valueUTF8;
      } catch {
        return undefined;
      }
    };

    // get jsons from static server
    Vue.prototype.$getJSON =async function (json) {
      var Axios  = require('axios')
      var data = []
       await Axios.get(`https://static.telephone.zone/json/${json}.json`).then((response) => {
        // console.log("countries.json from getCountry() in Dashboard", response.data)
        data = response.data
      });
      // console.log("data ", data)
      return data
    };

    // capital first letter for all words in text
    Vue.prototype.$CapitalText = function (text) {
      if (!isNaN(text)) return text;
      if (!text || text === null || text === undefined) return "-";
      let textArr = text.split(" ");
      for (let i = 0; i < textArr.length; i++) {
        if (isNaN(text)) {
          textArr[i] = textArr[i].charAt(0).toUpperCase() + textArr[i].slice(1);
        }
      }
      let txt = textArr.join(" ");
      return txt;
    };

    // capital first letter for first word in text
    Vue.prototype.$CapitalWord = function (word) {
      if (!word) return "";
      let txt = word.charAt(0).toUpperCase() + word.slice(1);
      return txt;
    };

    // truncating characters
    Vue.prototype.$TruncateChars = function (text, len, dot = true) {
      if (!text) return "";
      // return text.length > len ? text.slice(0, len-3).trim().concat('...') : text
      if (dot)
        return text.length > len
          ? text
              .slice(0, len - 3)
              .trim()
              .concat("...")
          : text;
      else return text.length > len ? text.slice(0, len).trim() : text;
    };

    // truncating email
    Vue.prototype.$TruncateEmail = function (email, dot = true) {
      if (!email) return "";
      // return text.length > len ? text.slice(0, len-3).trim().concat('...') : text
      if (dot) return email.split("@")[0].trim().concat("@...");
      else return email.split("@")[0].trim().concat("@");
    };

    // Trim
    Vue.prototype.$Trim = function (text) {
      if (text !== null && text !== undefined) {
        text = text.toString();
        return text.trim();
      }
      return;
    };

    // compare object types (clone to genuine)
    Vue.prototype.$Compairing = function (clone, genuine) {
      return JSON.stringify(clone) == JSON.stringify(genuine)
    };
    
    // compare 'dataClone' Object and 'data' Object return true or false
    Vue.prototype.$ObjsHasDiff = function (dataClone, data) {
      let filtered = Object.keys(dataClone).filter(elm => {
        // console.log("ObjsHasDiff", dataClone[elm] , data[elm])
        return dataClone[elm] !== data[elm] ? true : false
      })
      return filtered.length ? true : false
    };

    // get your Currency object
    Vue.prototype.$CurrencyObj = function (currencies, currency, code=true ) {
      return code ?
      currencies.find(currencyObj => currencyObj.code == currency.code) :
      currencies.find(currencyObj => currencyObj.code == currency)
    };

    // seprated price
    Vue.prototype.$PriceSeprator = function (price) {
      let priceSplit = price.toString().split(".")
      price = priceSplit[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      return  priceSplit.length > 1 ? `${price}.${priceSplit[1]}` : price;
    };

    // return seprate time & day (date) from standard date
    Vue.prototype.$DecorationDate = function (date) {
      return {
        day: date.split("T")[0],
        time: date.split("T")[1].slice(0, 5),
        hh_mm: date.split("T")[1].slice(0, 5),
        hh_mm_ss: date.split("T")[1].slice(0, 8),
        day_time: `${date.split("T")[0]}&nbsp;&nbsp;${date.split("T")[1].slice(0, 5)}<small class='ms-1'>UTC</small>`,
        day_time_seconds: `${date.split("T")[0]}&nbsp;&nbsp;${date.split("T")[1].slice(0, 8)}<small class='ms-1'>UTC</small>`
      }
    };

    // return decimal for number needed to decimal
    Vue.prototype.$LowDecimal = function (price, currency_decimal=2, decimal=6) {
      let length = Boolean(price.toString().split('.')[1]) ? price.toString().split('.')[1].length : 0

      if(length >= decimal) return (+price).toFixed(+decimal)
      else if((length < decimal) && (length > currency_decimal)) return (+price).toFixed(+length)
      else if(length <= currency_decimal) return (+price).toFixed(+currency_decimal)
      else return (+price).toFixed(0)
    };

    // round up number
    // USE CALCULATE OPERATIONS PURE JAVA-SCRIPT
    // Vue.prototype.$RoundUp = function (price, decimal) {
    //   let round = ((+10)**(+decimal))
    //   return (Math.ceil((+price) * (+round)) / (+round)).toFixed(+decimal)
    // };

    // round up number
    // USE DECIMAL.JS LIB
    Vue.prototype.$RoundUp = function (price, decimal) {
      const Decimal = require('decimal.js');
      //   // plus() ============> sum ----> (1.1 + 2.2)
      //   // minus() ===> subtraction ----> (0.3 - 0.1)
      //   // mul() ========> multiply --> (79.99 * 100)
      //   // div() ========> division -> (9999.8 / 100)
      //   // pow() ==> exponentiation ->          (pow)
      
      let round = new Decimal(10).pow(+decimal)
      let needRound = new Decimal(+price).decimalPlaces() <= +decimal
      let xxx = new Decimal(+price).decimalPlaces()
      
      // return needRound ? (+price).toFixed(+decimal) : (+(new Decimal((new Decimal(+price).mul(round)).ceil()).div(round)).valueOf()).toFixed(+decimal)
      // return needRound ? (+price).toFixed(+decimal) : (+(new Decimal((new Decimal(+price).mul(round))).div(round)).valueOf()).toFixed(+decimal)
      return needRound ? (+price).toFixed(+decimal) : 
        (new Decimal(Math.ceil(+((new Decimal(+price).mul(round)).valueOf()))).div(round)).valueOf()
    };

    // return financial data per define currency
    // USE CALCULATE OPERATIONS PURE JAVA-SCRIPT
    // Vue.prototype.$Exchange = function (currencies, gateway, price, rate) {
    //   if(Boolean(rate)) gateway.rate = rate
    //   let currency = currencies.find(currencyObj => currencyObj.code == gateway.currency_code)
    //   let round = ((+10)**(+currency.decimal))
    //   let purePrice = this.$RoundUp(((+price)*(+gateway.rate)), 8)
    //   let fee = this.$RoundUp(((((+price)*((+gateway.fee_percent)/100))*(+gateway.rate))+(+gateway.fixed_fee)), 8)
    //   let cost = {
    //     symbol: currency.symbol,
    //     price: (Math.ceil((+purePrice) * (+round)) / (+round)).toFixed(+currency.decimal),
    //     wage: (Math.ceil((+fee) * (+round)) / (+round)).toFixed(+currency.decimal),
    //     total: (Math.ceil((((+purePrice)+(+fee))) * (+round)) / (+round)).toFixed(+currency.decimal)
    //   }
    //   return cost
    // };

    // return financial data per define currency
    // USE DECIMAL.JS LIB
    Vue.prototype.$Exchange = function (currencies, gateway, price, rate) {
      const Decimal = require('decimal.js');
      //   // plus() ============> sum ----> (1.1 + 2.2)
      //   // minus() ===> subtraction ----> (0.3 - 0.1)
      //   // mul() ========> multiply --> (79.99 * 100)
      //   // div() ========> division -> (9999.8 / 100)
      //   // pow() ==> exponentiation ->          (pow)
      
      if(Boolean(rate)) gateway.rate = rate
      let currency = currencies.find(currencyObj => currencyObj.code == gateway.currency_code)

      let purePrice = new Decimal(+price).mul(new Decimal(+gateway.rate))
      let fee = ((new Decimal(+price).mul(new Decimal(new Decimal(+gateway.fee_percent).div(new Decimal(100))))).mul(new Decimal(+gateway.rate))).plus(new Decimal(gateway.fixed_fee))
      let cost = {
        symbol: currency.symbol,
        price: this.$RoundUp(+(purePrice.valueOf()), +currency.decimal),
        wage: this.$RoundUp(+(fee.valueOf()), +currency.decimal),
        total: this.$RoundUp(+(new Decimal(+purePrice).plus(new Decimal(+fee)).valueOf()) , +currency.decimal)
        // total: new Decimal(+purePrice).plus(new Decimal(+fee))
      }
      return cost
    };

    // for change price for other currency
    // USE JS-BIG-DECIMAL LIB
    // Vue.prototype.$ExchangePrice = function (primaryCurrency, price, notNeedLowDecimal=false) {
    //   var bigDecimal = require('js-big-decimal')

    //   let multiplication = primaryCurrency.hasOwnProperty('rate') 
    //     ?
    //     new bigDecimal(+price).multiply(new bigDecimal(+primaryCurrency.rate))
    //     :
    //     new bigDecimal(+price).multiply(new bigDecimal(+store.getters.getState('currency', 'rate')))

    //   let length = Boolean(price.toString().split('.')[1]) ? price.toString().split('.')[1].length : 3
    //   if(!notNeedLowDecimal){
    //     if(primaryCurrency.code.toLowerCase() == 'irr') 
    //       return `${
    //         this.$PriceSeprator(this.$LowDecimal(+multiplication.value, primaryCurrency.decimal, length))}${
    //           primaryCurrency.symbol}`
    //     else return `${primaryCurrency.symbol}${
    //       this.$PriceSeprator(this.$LowDecimal(+multiplication.value, primaryCurrency.decimal, length))}`
    //   } else {
    //     if(primaryCurrency.code.toLowerCase() == 'irr') 
    //       return `${
    //         this.$PriceSeprator(this.$RoundUp(+multiplication.value, primaryCurrency.decimal))}${
    //           primaryCurrency.symbol}`
    //     else return `${primaryCurrency.symbol}${
    //       this.$PriceSeprator(this.$RoundUp(+multiplication.value, primaryCurrency.decimal))}`
    //   } 
    // };

    // for change price for other currency
    // USE DECIMAL.JS LIB
    Vue.prototype.$ExchangePrice = function (primaryCurrency, price, notNeedLowDecimal=false) {
      const Decimal = require('decimal.js');
      //   // plus() ============> sum ----> (1.1 + 2.2)
      //   // minus() ===> subtraction ----> (0.3 - 0.1)
      //   // mul() ========> multiply --> (79.99 * 100)
      //   // div() ========> division -> (9999.8 / 100)
      //   // pow() ==> exponentiation ->          (pow)

      let multiplication = primaryCurrency.hasOwnProperty('rate') 
        ?
        new Decimal(+price).mul(new Decimal(+primaryCurrency.rate))
        :
        new Decimal(+price).mul(new Decimal(+store.getters.getState('currency', 'rate')))

      let length = Boolean(price.toString().split('.')[1]) ? price.toString().split('.')[1].length : 3
      if(!notNeedLowDecimal){
        if(primaryCurrency.code.toLowerCase() == 'irr') 
          return `${
            this.$PriceSeprator(this.$LowDecimal(+multiplication.valueOf(), primaryCurrency.decimal, length))}${
              primaryCurrency.symbol}`
        else return `${primaryCurrency.symbol}${
          this.$PriceSeprator(this.$LowDecimal(+multiplication.valueOf(), primaryCurrency.decimal, length))}`
      } else {
        if(primaryCurrency.code.toLowerCase() == 'irr') 
          return `${
            this.$PriceSeprator(this.$RoundUp(+multiplication.valueOf(), primaryCurrency.decimal))}${
              primaryCurrency.symbol}`
        else return `${primaryCurrency.symbol}${
          this.$PriceSeprator(this.$RoundUp(+multiplication.valueOf(), primaryCurrency.decimal))}`
      } 
    };

    // add delay (sleep)
    Vue.prototype.$Sleep = async function (ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    };

    // use js-big-decimal library for math operations
    Vue.prototype.$BigDecimalOperation = function (first, seconde=0, operator) {
      // refrence :  https://www.npmjs.com/package/js-big-decimal#addaugend-addend
      // operator : add(+) | subtract(-) | multiply(*) | compareTo(>=<)
      var bigDecimal = require('js-big-decimal')
      let a = new bigDecimal(+first)
      let b = new bigDecimal(+seconde)
      let result
      if(operator == 'add') result = (a.add(b)).value
      else if(operator == 'subtract') result = (a.subtract(b)).value
      else if(operator == 'multiply') result = (a.multiply(b)).value
      else if(operator == 'compareTo') result = a.compareTo(b) // (> 1) & (< -1) & (= 0)
      return result
    };

    // use decimal library for math operations
    Vue.prototype.$DecimalOperation = function (first, seconde, operator) {
      const Decimal = require('decimal.js');
      // valueOf() return value i script tag
      //   // plus() ============> sum ----> (1.1 + 2.2)
      //   // minus() ===> subtraction ----> (0.3 - 0.1)
      //   // mul() ========> multiply --> (79.99 * 100)
      //   // div() ========> division -> (9999.8 / 100)
      //   // pow() ==> exponentiation ->          (2^3)

      let a = new Decimal(+first)
      let b = new Decimal(+seconde)
      switch (operator) {
        case 'plus': return a.plus(b)
        case '+': return a.plus(b)
        case 'minus': return a.minus(b)
        case '-': return a.minus(b)
        case 'mul': return a.mul(b)
        case '*': return a.mul(b)
        case 'div': return a.div(b)
        case '/': return a.div(b)
        case 'pow': return a.pow(b)
        case '^': return a.pow(b)
        default: return 'Operator not found'
      }
    };

    // convert only en number to fa number
    Vue.prototype.$ToPersianNumber = function (number) {
      const faNumber = {
        1: '۱',
        2: '۲',
        3: '۳',
        4: '۴',
        5: '۵',
        6: '۶',
        7: '۷',
        8: '۸',
        9: '۹',
        0: '۰',
      }
      return number.toString().replace(/\d/g, x => faNumber[x])
    };

    // convert date to specific time zone
    Vue.prototype.$ConvertDate = function (date, lan='fa-IR', timeZone='Asia/Tehran') {
      // date: timestamp(number)(1691240600000) | date.toJSON()(string)(2023-08-05T13:04:13.112798Z)
      let converted = new Date(date).toLocaleString(lan, {
        timeZone: timeZone
      }).split(',')
      return {
        day: converted[0].replace(/\//g, "-").trim(),
        time: converted[1].trim().slice(0, 5),
        hh_mm: converted[1].trim().slice(0, 5),
        hh_mm_ss: converted[1].trim().slice(0, 8),
        day_time: `${converted[0].replace(/\//g, "-").trim()}&nbsp;&nbsp;${converted[1].trim().slice(0, 5)}<small class='ms-1'>Asia/Tehran</small>`
      }
    };
  },
};
